/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

// アンカーリンクを押すと、スクロール(エラー出るのでコメントアウト)
  // $('a[href^=#]:not([class*="modal"])').click(function() {
  //     var speed = 400;
  //     var href= $(this).attr("href");
  //     var target = $(href == "#" || href == "" ? 'html' : href);
  //     var position = target.offset().top;
  //     $('body,html').animate({scrollTop:position}, speed, 'swing');
  //     return false;
  // });

});
